.logo-sized(@size) {
  display: block;
  width: @size;
  height: @size*0.64;
  background-color: var(--color-base-contrast);
  mask: url("allude-logo.svg") center/@size no-repeat;
  position: relative;
  flex-shrink: 0;

  &:hover {
    background: linear-gradient(137deg, var(--color-accent) 25%, var(--color-accent-shift) 95%);
  }
}

.logo-small {
  .logo-sized(70px);
}

.logo-regular {
  .logo-sized(90px);
}

.logo-large {
  .logo-sized(120px);
}
