@import "~/lib/ui/index";
@import "Passport/home-page";

.passport {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .footer-vertical();
}
