@import "mixins";
@import "fonts";

* {
  box-sizing: border-box;
}

html {
  @default-rem-modifier: 1.6;
  font-size: (100% / @default-rem-modifier);
}

body {
  .font-ui();
  overflow: hidden;

  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

a {
  color: var(--color-base-contrast);

  &:hover {
    color: var(--color-accent-strong);
  }
}

menu {
  padding: 0;
  margin: 0;
}

input {
  .strip();
  font: inherit;
  letter-spacing: inherit;
}

button {
  .strip();
}
