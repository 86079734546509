@import "../mixins";
@import "../Inputs/link";

.footer {
  .font-ui-small();
  flex-grow: 0;
  color: var(--color-text-subtle);
}

.footer-horizontal {
  .footer {
    width: 100%;
    text-align: center;
    padding: 10px 0 0 0;
  }
}

.footer-vertical {
  .footer {
    box-sizing: border-box;
    padding-bottom: 1em;
    .b2t();

    position: absolute;
    bottom: 0;
    right: 0;
  }
}
