@import "../mixins";
@import "link";
@import "input";
@import "vars";

.button() {
  .strip();
  cursor: pointer;
  padding: 0 @input-height*0.9;
  height: @input-height;
  border-radius: 3rem;
  text-align: center;
}

.button-primary {
  .button();
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);

  &:hover {
    background: var(--color-accent-subtle);
  }

  &:active {
    background-color: var(--color-accent);
  }
}

.button-link {
  .strip();
  cursor: pointer;
  border-bottom: @space2 solid transparent;

  &:hover {
    border-bottom: @space2 solid var(--color-accent-subtle);
  }
}
