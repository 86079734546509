* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  letter-spacing: .1px;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: Fira Sans, Avenir, Tahoma, sans-serif;
  font-size: 1.4rem;
  line-height: 1.2em;
  overflow: hidden;
}

a {
  color: var(--color-base-contrast);
}

a:hover {
  color: var(--color-accent-strong);
}

menu {
  margin: 0;
  padding: 0;
}

input {
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

button {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.theme-light {
  --color-base-0: #fafafa;
  --color-base-1: #f5f5f4;
  --color-base-2: #eeeded;
  --color-base-3: #e6e5e5;
  --color-base-4: #d7d5d5;
  --color-base-5: #c1bebd;
  --color-base-contrast: black;
  --color-base-contrast-subtle: #5c5757;
  --color-accent: #00f0e0;
  --color-accent-subtle: #66f6ec;
  --color-accent-strong: #00a89d;
  --color-accent-shift: #461dfc;
  --color-accent-contrast: black;
  --color-complement: #ff4caf;
  --color-complement-subtle: #ff8bcb;
  --color-complement-strong: #a63272;
  --color-complement-contrast: black;
  --color-success: #12ba60;
  --color-error: #e0246c;
  background-color: var(--color-base-1);
  color: var(--color-base-contrast);
}

.theme-dark {
  --color-base-0: #171717;
  --color-base-1: #1f1e1e;
  --color-base-2: #272626;
  --color-base-3: #313030;
  --color-base-4: #403f3f;
  --color-base-5: #4d4c4c;
  --color-base-contrast: white;
  --color-base-contrast-subtle: #c0bfbf;
  --color-accent: #00f0e0;
  --color-accent-subtle: #00ada1;
  --color-accent-strong: tint(#00f0e0. 30%);
  --color-accent-shift: #461dfc;
  --color-accent-contrast: black;
  --color-complement: #ff4caf;
  --color-complement-subtle: #a63272;
  --color-complement-strong: #ff4caf;
  --color-complement-contrast: black;
  --color-success: #12ba60;
  --color-error: #e0246c;
  background-color: var(--color-base-1);
  color: var(--color-base-contrast);
}

.page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.strip {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

@keyframes loading {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.input {
  width: 100%;
  margin: 6px 0;
}

.input input {
  background-color: var(--color-base-3);
  height: 5.2rem;
  width: 100%;
  text-align: center;
}

.input input:hover {
  background-color: var(--color-base-4);
}

.input input:focus {
  outline: none;
}

.input-password {
  width: 100%;
  margin: 6px 0;
}

.input-password input {
  background-color: var(--color-base-3);
  height: 5.2rem;
  width: 100%;
  text-align: center;
}

.input-password input:hover {
  background-color: var(--color-base-4);
}

.input-password input:focus {
  outline: none;
}

.input-title {
  color: var(--color-base-contrast-subtle);
  font-variant: all-small-caps;
  text-align: center;
  width: 100%;
}

.input-password-footer {
  text-align: left;
  flex-flow: row;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.input-password-footer > div {
  flex-grow: 1;
}

.input-validation-rule {
  color: var(--color-base-contrast-subtle);
}

.input-validation-rule-ok {
  color: var(--color-success);
}

.input-validation-check {
  width: .9em;
  display: inline-block;
}

.button-primary {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  cursor: pointer;
  height: 5.2rem;
  text-align: center;
  background: none;
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
  border: none;
  border-radius: 3rem;
  outline: none;
  margin: 0;
  padding: 0 4.68rem;
}

.button-primary:hover {
  background: var(--color-accent-subtle);
}

.button-primary:active {
  background-color: var(--color-accent);
}

.button-link {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 6px solid #0000;
  outline: none;
  margin: 0;
  padding: 0;
}

.button-link:hover {
  border-bottom: 6px solid var(--color-accent-subtle);
}

.link {
  color: inherit;
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
  color: var(--color-link-hover);
  text-decoration: underline;
}

.link-external {
  color: inherit;
  text-decoration: underline;
}

.link-external:hover {
  cursor: pointer;
  color: var(--color-link-hover);
  cursor: pointer;
  color: var(--color-link-hover);
  text-decoration: underline;
}

.link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid var(--color-text);
  display: inline-block;
  position: relative;
  top: -.55em;
  transform: rotate(-45deg);
}

.link-external:hover:after {
  border-left: 5px solid var(--color-link-hover);
}

.b2t {
  writing-mode: sideways-lr;
}

.b2t .link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid #0000;
  border-bottom: 5px solid var(--color-text);
  border-right: 5px solid #0000;
  display: inline-block;
  position: relative;
  top: -.1em;
  left: -.55em;
  transform: rotate(-45deg);
}

.b2t .link-external:hover:after {
  border-bottom: 5px solid var(--color-link-hover);
}

.link-external-arrow {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  transform: rotate(-45deg);
}

.logo-small {
  width: 70px;
  height: 44.8px;
  background-color: var(--color-base-contrast);
  flex-shrink: 0;
  display: block;
  position: relative;
  -webkit-mask: url(allude-logo.26e1abab.svg) center / 70px no-repeat;
  mask: url(allude-logo.26e1abab.svg) center / 70px no-repeat;
}

.logo-small:hover {
  background: linear-gradient(137deg, var(--color-accent) 25%, var(--color-accent-shift) 95%);
}

.logo-regular {
  width: 90px;
  height: 57.6px;
  background-color: var(--color-base-contrast);
  flex-shrink: 0;
  display: block;
  position: relative;
  -webkit-mask: url(allude-logo.26e1abab.svg) center / 90px no-repeat;
  mask: url(allude-logo.26e1abab.svg) center / 90px no-repeat;
}

.logo-regular:hover {
  background: linear-gradient(137deg, var(--color-accent) 25%, var(--color-accent-shift) 95%);
}

.logo-large {
  width: 120px;
  height: 76.8px;
  background-color: var(--color-base-contrast);
  flex-shrink: 0;
  display: block;
  position: relative;
  -webkit-mask: url(allude-logo.26e1abab.svg) center / 120px no-repeat;
  mask: url(allude-logo.26e1abab.svg) center / 120px no-repeat;
}

.logo-large:hover {
  background: linear-gradient(137deg, var(--color-accent) 25%, var(--color-accent-shift) 95%);
}

.footer {
  letter-spacing: .1px;
  color: var(--color-text-subtle);
  flex-grow: 0;
  font-family: Fira Sans, Avenir, Tahoma, sans-serif;
  font-size: 1.3rem;
  line-height: 1.2em;
}

.footer-horizontal .footer {
  width: 100%;
  text-align: center;
  padding: 10px 0 0;
}

.footer-vertical .footer {
  box-sizing: border-box;
  writing-mode: sideways-lr;
  padding-bottom: 1em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer-vertical .footer .link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid #0000;
  border-bottom: 5px solid var(--color-text);
  border-right: 5px solid #0000;
  display: inline-block;
  position: relative;
  top: -.1em;
  left: -.55em;
  transform: rotate(-45deg);
}

.footer-vertical .footer .link-external:hover:after {
  border-bottom: 5px solid var(--color-link-hover);
}

.footer-vertical .footer .link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid #0000;
  border-bottom: 5px solid var(--color-text);
  border-right: 5px solid #0000;
  display: inline-block;
  position: relative;
  top: -.1em;
  left: -.55em;
  transform: rotate(-45deg);
}

.footer-vertical .footer .link-external:hover:after {
  border-bottom: 5px solid var(--color-link-hover);
}

.search-default-colors .search-bar {
  background-color: var(--color-base-strong);
}

.search-default-colors .search-result:hover {
  background-color: var(--color-stark);
}

.search-default-colors .search-result-loading {
  background: linear-gradient(100deg, var(--color-base-strong) 30%, var(--color-base) 50%, var(--color-base-strong) 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

.search-default-colors .search-results-filter {
  background-color: var(--color-base-strong);
}

.search-default-colors .search-results-filter:hover {
  background-color: var(--color-midtone-subtle);
}

.search-editor-colors .search-bar, .search-editor-colors .search-result:hover {
  background-color: var(--color-base);
}

.search-editor-colors .search-result-loading {
  background: linear-gradient(100deg, var(--color-base) 30%, var(--color-stark) 50%, var(--color-base) 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

.search-editor-colors .search-results-filter {
  background-color: var(--color-base);
}

.search-editor-colors .search-results-filter:hover {
  background-color: var(--color-base-strong);
}

.search {
  height: 100%;
  box-sizing: border-box;
  flex: 1 0 auto;
}

.search-bar {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.search-bar.search-bar-active, .search-bar:hover {
  background: linear-gradient(90deg, var(--color-accent) 15%, var(--color-accent-shift) 85%);
}

.search-bar .search-icon {
  width: 4rem;
  height: 4rem;
  box-sizing: border-box;
  background: url(icons8-search-96.0f3f20c8.png) center / 2.2rem no-repeat;
  margin-left: 1.33333rem;
}

.search-bar .search-clear-button {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin: 0 .666667rem 0 0;
  padding: 0;
  display: flex;
}

.search-bar .search-clear-button .search-clear-button-bg {
  width: 100%;
  height: 100%;
  border-radius: 2rem;
}

.search-bar .search-clear-button .search-clear-button-bg:hover {
  background-color: #0003;
}

.search-bar .search-clear-button .search-clear-button-icon {
  width: 100%;
  height: 100%;
  background: url(icons8-close-96.bbba540b.png) center / 1.4rem no-repeat;
}

.search-bar input {
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  box-sizing: border-box;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  flex-grow: 1;
  margin: 0;
  padding: 0 0 0 3px;
}

.search-results {
  flex-grow: 1;
  padding: 10px 0;
}

.search-results .search-results-filters {
  padding: 0 10px;
}

.search-results .search-results-filter {
  border-radius: 2em;
  margin: 0 3px;
  padding: 2px 6px;
}

.search-results .search-result {
  border-radius: 2px;
  margin: 10px 0;
  padding: 5px 10px;
  font-family: Fira Sans, Avenir, Tahoma, sans-serif;
  font-size: 1.8rem;
  line-height: 1.3em;
}

.search-results .search-result-loading {
  height: 3em;
  box-sizing: border-box;
  margin: 0 10px 20px;
}

.search-results .search-results-filters, .search-results .search-results-filters .search-results-filter {
  color: var(--color-contrast-subtle);
}

.link {
  color: inherit;
  text-decoration: underline;
}

.link:hover {
  cursor: pointer;
  color: var(--color-link-hover);
  text-decoration: underline;
}

.link-external {
  color: inherit;
  text-decoration: underline;
}

.link-external:hover {
  cursor: pointer;
  color: var(--color-link-hover);
  cursor: pointer;
  color: var(--color-link-hover);
  text-decoration: underline;
}

.link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid var(--color-text);
  display: inline-block;
  position: relative;
  top: -.55em;
  transform: rotate(-45deg);
}

.link-external:hover:after {
  border-left: 5px solid var(--color-link-hover);
}

.b2t {
  writing-mode: sideways-lr;
}

.b2t .link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid #0000;
  border-bottom: 5px solid var(--color-text);
  border-right: 5px solid #0000;
  display: inline-block;
  position: relative;
  top: -.1em;
  left: -.55em;
  transform: rotate(-45deg);
}

.b2t .link-external:hover:after {
  border-bottom: 5px solid var(--color-link-hover);
}

.link-external-arrow {
  content: "";
  width: 0;
  height: 0;
  display: inline-block;
  position: relative;
  transform: rotate(-45deg);
}

.folding-page-menu {
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.folding-page-menu > * {
  box-sizing: border-box;
  margin: 10px 0;
}

.folding-page-menu menuitem {
  width: 100%;
  text-align: center;
}

.folding-page-menu .folding-menu-item-content {
  padding: 0 10%;
}

.folding-page-menu .folding-menu-submenu {
  height: 1.5em;
  padding: .2em 0;
}

.folding-page-menu .folding-menu-submenu a:hover {
  color: var(--color-link-hover);
}

.folding-page-menu .folding-menu-submenu-title-link {
  color: inherit;
  text-decoration: underline;
}

.folding-page-menu .folding-menu-submenu-title-link:hover {
  cursor: pointer;
  color: var(--color-link-hover);
  cursor: pointer;
  color: var(--color-link-hover);
  text-decoration: underline;
}

.folding-page-menu .folding-menu-submenu-title:after {
  content: " ➞";
}

.folding-page-menu .folding-menu-submenu-open {
  background-color: var(--color-complement);
  position: relative;
}

.folding-page-menu .folding-menu-submenu-open:after {
  content: "";
  float: right;
  width: 0;
  height: 0;
  border-top: .75em solid #0000;
  border-left: .75em solid var(--color-complement);
  border-bottom: .75em solid #0000;
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: -.75em;
}

.folding-page-menu .folding-menu-submenu-open .folding-menu-submenu-title {
  color: var(--color-stark);
}

.folding-page {
  height: 100%;
  width: 100%;
  background-color: var(--color-base-2);
  flex-flow: row;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.folding-page .panel-1, .folding-page .panel-2, .folding-page .panel-3 {
  background-color: var(--color-base-1);
  width: 25%;
  height: 100%;
  flex-flow: column;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0 3vw;
  display: flex;
}

.folding-page .panel-spacer-before {
  width: 12.5%;
  height: 100%;
  border-right: 6px solid var(--color-base-1);
}

.folding-page .panel-spacer-after {
  height: 100%;
  border-left: 6px solid var(--color-base-1);
  flex-grow: 1;
}

.folding-page .panel-1 {
  border-left: 6px solid var(--color-base-2);
  border-right: 6px solid var(--color-base-2);
}

.folding-page .panel-2, .folding-page .panel-3 {
  border-right: 6px solid var(--color-base-2);
}

.folding-page .group {
  width: 100%;
}

.typewriter {
  letter-spacing: -.01em;
  font-family: Fira Code, monospace;
  display: inline-block;
}

.typewriter .typewriter-caret {
  height: 1em;
  width: .1em;
  background-color: var(--color-complement);
  display: inline-block;
  position: relative;
  top: .15em;
}

.typewriter .typewriter-caret-blink {
  animation: typewriter-caret-blink-anim 1.1s step-start .5s infinite;
}

@keyframes typewriter-caret-blink-anim {
  0% {
    background-color: var(--color-complement);
  }

  50% {
    background-color: #0000;
  }
}

.passport .home-page {
  width: 100%;
  height: 100%;
}

.passport .background {
  width: 100%;
  height: 100%;
  background-blend-mode: overlay, normal;
  background: radial-gradient(#ffebee, #7676e0) 0 / 200% 200%, linear-gradient(270deg, #bffbf7, #e3ddff, #ffd2eb) 0 / 400% 400%;
  justify-content: center;
  align-items: center;
  animation: bg-shift-anim 5s linear infinite;
  display: flex;
  position: absolute;
}

@keyframes bg-shift-anim {
  0% {
    background-position: 0 0, 0;
  }

  25% {
    background-position: 100% 0, 100%;
  }

  50% {
    background-position: 100% 100%, 100%;
  }

  75% {
    background-position: 0 100%, 100%;
  }

  100% {
    background-position: 0 0, 0;
  }
}

.passport .logo-giant {
  width: 140vh;
  height: 80vh;
  background: #ffffff80;
  display: block;
  position: relative;
  -webkit-mask: url(allude-logo.26e1abab.svg) center / 140vh no-repeat;
  mask: url(allude-logo.26e1abab.svg) center / 140vh no-repeat;
}

.passport .blurb {
  padding: 60px 8%;
  font-size: 4.5rem;
  font-weight: 200;
  line-height: 1em;
  position: absolute;
  bottom: 0;
  left: 0;
}

.passport .blurb .paragraph-spacer {
  margin-bottom: .8em;
}

.passport .blurb p {
  margin-top: .3em;
  margin-bottom: 0;
}

.passport {
  width: 100%;
  height: 100%;
  flex-flow: row;
  align-items: center;
  display: flex;
}

.passport .footer {
  box-sizing: border-box;
  writing-mode: sideways-lr;
  padding-bottom: 1em;
  position: absolute;
  bottom: 0;
  right: 0;
}

.passport .footer .link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid #0000;
  border-bottom: 5px solid var(--color-text);
  border-right: 5px solid #0000;
  display: inline-block;
  position: relative;
  top: -.1em;
  left: -.55em;
  transform: rotate(-45deg);
}

.passport .footer .link-external:hover:after {
  border-bottom: 5px solid var(--color-link-hover);
}

.passport .footer .link-external:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid #0000;
  border-bottom: 5px solid var(--color-text);
  border-right: 5px solid #0000;
  display: inline-block;
  position: relative;
  top: -.1em;
  left: -.55em;
  transform: rotate(-45deg);
}

.passport .footer .link-external:hover:after {
  border-bottom: 5px solid var(--color-link-hover);
}

/*# sourceMappingURL=passport.21b65c5c.css.map */
