@main-font-family: 'Fira Sans', Avenir, Tahoma, sans-serif;

.font-ui() {
  font-family: @main-font-family;
  font-size: 1.4rem;
  line-height: 1.2em;
  letter-spacing: 0.1px;
}

.font-ui-small() {
  .font-ui();
  font-size: 1.3rem;
}

.font-ui-large() {
  .font-ui();
  font-size: 1.6rem;
}

.font-ui-title() {
  font-family: @main-font-family;
  font-size: 1.8rem;
  font-variant: petite-caps;
  font-weight: 500;
  letter-spacing: -0.01em;
}

.font-copy() {
  font-family: @main-font-family;
  font-size: 1.8rem;
  line-height: 1.3em;
}

.font-mono() {
  font-family: 'Fira Code', monospace;
}
