@import "../fonts";

.typewriter {
  .font-mono();
  display: inline-block;
  letter-spacing: -0.01em;

  .typewriter-caret {
    height: 1em;
    width: 1em*0.1;
    top: 1em*0.15;
    position: relative;
    display: inline-block;
    background-color: var(--color-complement);
  }

  .typewriter-caret-blink {
    animation: typewriter-caret-blink-anim 1.1s steps(1, jump-start) infinite;
    animation-delay: 0.5s;
  }

  @keyframes typewriter-caret-blink-anim {
    0% {
      background-color: var(--color-complement);
    }
    50% {
      background-color: transparent;
    }
  }
}
