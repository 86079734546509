@import "../mixins";
@import "vars";

.input {
  width: 100%;
  margin: @space2 0;

  input {
    background-color: var(--color-base-3);
    height: @input-height;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: var(--color-base-4);
    }

    &:focus {
      outline: transparent;
    }
  }
}

.input-password {
  .input();
}

.input-title {
  color: var(--color-base-contrast-subtle);
  font-variant: all-small-caps;
  text-align: center;
  width: 100%;
}

.input-password-footer {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;

  & > div {
    flex-grow: 1;
  }
}

.input-validation-rule {
  color: var(--color-base-contrast-subtle);

  &-ok {
    color: var(--color-success);
  }
}

.input-validation-check {
  width: 0.9em;
  display: inline-block;
}
