.link {
  color: inherit;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    color: var(--color-link-hover);
    text-decoration: underline;
  }
}

@arrow-size: 5px;

.link-external {
  .link();

  &:after {
    .link-external-arrow();
    top: -0.55em;
    border-top: @arrow-size solid transparent;
    border-bottom: @arrow-size solid transparent;
    border-left: @arrow-size solid var(--color-text);
  }

  &:hover:after {
    border-left: @arrow-size solid var(--color-link-hover);
  }
}

.b2t {
  writing-mode: sideways-lr;

  .link-external {
    &:after {
      .link-external-arrow();
      top: -0.1em;
      left: -0.55em;
      border-left: @arrow-size solid transparent;
      border-bottom: @arrow-size solid var(--color-text);
      border-right: @arrow-size solid transparent;
    }

    &:hover:after {
      border-bottom: @arrow-size solid var(--color-link-hover);
    }
  }
}

.link-external-arrow {
  display: inline-block;
  content: "";
  width: 0;
  height: 0;
  position: relative;
  transform: rotate(-45deg);
}
