.color-base(light) {
  @h: 10;
  @s: 3%;
  --color-base-0: hsl(@h, @s, 98%);
  --color-base-1: hsl(@h, @s, 96%);
  --color-base-2: hsl(@h, @s, 93%);
  --color-base-3: hsl(@h, @s, 90%);
  --color-base-4: hsl(@h, @s, 84%);
  --color-base-5: hsl(@h, @s, 75%);
  --color-base-contrast: black;
  --color-base-contrast-subtle: hsl(@h, @s, 35%);
}

.color-base(dark) {
  @h: 10;
  @s: 1%;
  --color-base-0: hsl(@h, @s, 9%);
  --color-base-1: hsl(@h, @s, 12%);
  --color-base-2: hsl(@h, @s, 15%);
  --color-base-3: hsl(@h, @s, 19%);
  --color-base-4: hsl(@h, @s, 25%);
  --color-base-5: hsl(@h, @s, 30%);
  --color-base-contrast: white;
  --color-base-contrast-subtle: hsl(@h, @s, 75%);
}

.color-accent(light) {
  @color-accent: hsl(176, 100%, 47%);
  --color-accent: @color-accent;
  --color-accent-subtle: tint(@color-accent, 40%);
  --color-accent-strong: shade(@color-accent, 30%);
  --color-accent-shift: hsl(251, 97%, 55%);
  --color-accent-contrast: black;
}

.color-accent(dark) {
  @color-accent: hsl(176, 100%, 47%);
  --color-accent: @color-accent;
  --color-accent-subtle: shade(@color-accent, 28%);
  --color-accent-strong: tint(@color-accent. 30%);
  --color-accent-shift: hsl(251, 97%, 55%);
  --color-accent-contrast: black;
}

.color-complement(light) {
  @color-complement: hsl(327, 100%, 65%);
  --color-complement: @color-complement;
  --color-complement-subtle: tint(@color-complement, 35%);
  --color-complement-strong: shade(@color-complement, 35%);
  --color-complement-contrast: black;
}

.color-complement(dark) {
  @color-complement: hsl(327, 100%, 65%);
  --color-complement: @color-complement;
  --color-complement-subtle: shade(@color-complement, 35%);
  --color-complement-strong: @color-complement;
  --color-complement-contrast: black;
}

.color-states(light) {
  --color-success: hsl(148, 82%, 40%);
  --color-error: hsl(337, 75%, 51%);
}

.color-states(dark) {
  --color-success: hsl(148, 82%, 40%);
  --color-error: hsl(337, 75%, 51%);
}

@themes: light, dark;

each(@themes, {
  .theme-@{value} {
    .color-base(@value);
    .color-accent(@value);
    .color-complement(@value);
    .color-states(@value);
    background-color: var(--color-base-1);
    color: var(--color-base-contrast);
  }
});
