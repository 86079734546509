@import "vars";

.page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.strip {
  padding: 0;
  margin: 0;
  border: none;
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background: none;
  outline: none;
}

// Loading

.loading(@color-1, @color-2) {
  background: linear-gradient(100deg, @color-1 30%, @color-2 50%, @color-1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
