@import "~/lib/ui/themes";

.passport {
  @color-accent: hsl(176, 100%, 47%);
  @color-accent-shift: hsl(252, 100%, 73%);
  @color-complement: hsl(327, 100%, 65%);

  @grad-1: tint(@color-accent, 75%);
  @grad-2: tint(@color-accent-shift, 75%);
  @grad-3: tint(@color-complement, 75%);

  @overlay-1: #ffebee;
  @overlay-2: #7676e0;

  .home-page {
    width: 100%;
    height: 100%;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;

    background: radial-gradient(@overlay-1, @overlay-2), linear-gradient(270deg, @grad-1, @grad-2, @grad-3);
    background-size: 200% 200%, 400% 400%;
    background-position: 0, 0 50%;
    background-blend-mode: overlay, normal;
    animation: bg-shift-anim 5s linear infinite;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @keyframes bg-shift-anim {
    0% {
      background-position: 0 0, 0 50%;
    }
    25% {
      background-position: 100% 0, 100% 50%;
    }
    50% {
      background-position: 100% 100%, 100% 50%;
    }
    75% {
      background-position: 0 100%, 100% 50%;
    }
    100% {
      background-position: 0 0, 0 50%;
    }
  }

  .logo-giant {
    @size: 80vh;

    display: block;
    width: @size*1.75;
    height: @size;

    background: hsla(0, 0%, 100%, 50%);
    mask: url("allude-logo.svg") center/@size*1.75 no-repeat;
    position: relative;
  }

  .blurb {
    position: absolute;
    padding: 60px 8%;
    left: 0;
    bottom: 0;
    font-size: 4.5rem;
    font-weight: 200;
    line-height: 1em;

    .paragraph-spacer {
      margin-bottom: 0.8em;
    }

    p {
      margin-top: 0.3em;
      margin-bottom: 0;
    }
  }
}

.color-states(light) {
}

.color-states(dark) {
}

each(@themes, {
  .theme-@{value} {
  }
});
