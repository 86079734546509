@import "../../../lib/ui/Inputs/input";
@import "../../../lib/ui/palette";

@search-active-gradient: linear-gradient(90deg, var(--color-accent) 15%, var(--color-accent-shift) 85%);

.search-default-colors {
  .search-bar {
    background-color: var(--color-base-strong);
  }

  .search-result:hover {
    background-color: var(--color-stark);
  }

  .search-result-loading {
    .loading(var(--color-base-strong), var(--color-base));
  }

  .search-results-filter {
    background-color: var(--color-base-strong);

    &:hover {
      background-color: var(--color-midtone-subtle);
    }
  }
}

.search-editor-colors {
  .search-bar {
    background-color: var(--color-base);
  }

  .search-result:hover {
    background-color: var(--color-base);
  }

  .search-result-loading {
    .loading(var(--color-base), var(--color-stark));
  }

  .search-results-filter {
    background-color: var(--color-base);

    &:hover {
      background-color: var(--color-base-strong);
    }
  }
}

@search-icon-size: 4rem;
@search-icon-offset: (@search-icon-size/3);

.search-sizing(@search-bar-height, @search-margin-top, @search-offset-left) {
  .search {
    padding-top: @search-margin-top;
  }

  .search-bar {
    height: @search-bar-height;
    border-radius: (@search-bar-height/2) 0 0 (@search-bar-height/2);
    width: calc(100% + @search-icon-size + @search-icon-offset + @search-offset-left);
    position: relative;
    left: calc(-@search-icon-size - @search-icon-offset - @search-offset-left);
  }
}

.search {
  flex: 1 0 auto;
  height: 100%;
  box-sizing: border-box;
}

.search-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  &.search-bar-active {
    background: @search-active-gradient;
  }

  &:hover {
    background: @search-active-gradient;
  }

  .search-icon {
    width: @search-icon-size;
    height: @search-icon-size;
    box-sizing: border-box;
    background: no-repeat center center/(@search-icon-size*0.55) url("../../../lib/ui/icons/icons8-search-96.png");
    margin-left: @search-icon-offset;
  }

  .search-clear-button {
    .strip();
    width: @search-icon-size;
    height: @search-icon-size;
    cursor: pointer;

    margin-right: (@search-icon-size/6);

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .search-clear-button-bg {
      width: 100%;
      height: 100%;
      border-radius: (@search-icon-size/2);

      &:hover {
        background-color: hsla(0, 0%, 0%, 20%);
      }
    }

    .search-clear-button-icon {
      width: 100%;
      height: 100%;
      background: no-repeat center center/(@search-icon-size*0.35) url("../../../lib/ui/icons/icons8-close-96.png");
    }
  }

  input {
    .strip();
    background: none;
    box-sizing: border-box;
    flex-grow: 1;
    padding-left: 3px;
    height: 100%;
  }
}

.search-results {
  flex-grow: 1;
  padding: 10px 0;

  .search-results-filters {
    padding: 0 10px;
  }

  .search-results-filter {
    margin: 0 3px;
    padding: 2px 6px;
    border-radius: 2em;
  }

  .search-result {
    .font-copy();
    margin: 10px 0;
    padding: 5px 10px;
    border-radius: 2px;
  }

  .search-result-loading {
    height: 3em;
    box-sizing: border-box;
    margin: 0 10px 20px 10px;
  }

  .search-results-filters {
    color: var(--color-contrast-subtle);

    .search-results-filter {
      color: var(--color-contrast-subtle);
    }
  }
}
