@import "../mixins";
@import "../vars";
@import "folding-menu";

.folding-page {
  @color-bg: var(--color-base-2);
  @color-panel: var(--color-base-1);

  .page();
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  background-color: @color-bg;

  .panel() {
    background-color: @color-panel;
    flex-grow: 0;
    width: 25%;
    height: 100%;
    padding: 0 3vw;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: @space8;
  }

  .panel-1, .panel-2, .panel-3 {
    .panel();
  }

  @stripe-panel: @space2 solid @color-panel;
  @stripe-bg: @space2 solid @color-bg;

  .panel-spacer-before {
    width: 12.5%;
    height: 100%;
    border-right: @stripe-panel;
  }

  .panel-spacer-after {
    flex-grow: 1;
    height: 100%;
    border-left: @stripe-panel;
  }

  .panel-1 {
    border-left: @stripe-bg;
    border-right: @stripe-bg;
  }

  .panel-2 {
    border-right: @stripe-bg;
  }

  .panel-3 {
    border-right: @stripe-bg;
  }

  .group {
    width: 100%;
  }
}
