@import "../inputs/link";

.folding-page-menu {
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 10px 0;
    box-sizing: border-box;
  }

  menuitem {
    width: 100%;
    text-align: center;
  }

  .folding-menu-item-content {
    padding: 0 10%;
  }

  @submenu-height: 1.5em;

  .folding-menu-submenu {
    height: @submenu-height;
    padding: 0.2em 0;

    a:hover {
      color: var(--color-link-hover);
    }
  }

  .folding-menu-submenu-title-link {
    .link();
  }

  .folding-menu-submenu-title {
    &:after {
      content: " ➞";
    }
  }

  .folding-menu-submenu-open {
    position: relative;
    background-color: var(--color-complement);

    &:after {
      content: "";
      display: inline-block;
      float: right;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      right: -(@submenu-height/2);

      border-top: (@submenu-height/2) solid transparent;
      border-left: (@submenu-height/2) solid var(--color-complement);
      border-bottom: (@submenu-height/2) solid transparent;
    }

    .folding-menu-submenu-title {
      color: var(--color-stark);
    }
  }
}
